<script setup>
	/* ----- 引用 START ----- */
	import { ref, reactive, onMounted, onBeforeMount, onBeforeUnmount, onUnmounted, watch } from 'vue';
	import { RouterView, useRoute, useRouter } from 'vue-router'
	
	import routers from '@/router'

	// 存储
	import { useUserInfoStore } from '@/stores/UserInfoStore.js';

	// 公共方法
	import { Jump, ShowMsg, webSocket, getExtractInfo } from '@/plugins/utils.js';
	import { ElLoading } from 'element-plus'

	// 接口
	import { toLogout, getSysConfig, clearCache } from '@/api/api';
	import { getAssets } from '@/api/bussiness.js';

	// 资源
	import logo from '@/assets/images/logo-white.png'

	// 组件
	import Breadcrumb  from '@/components/web/breadcrumb.vue'
	/* ----- 引用 END ----- */

    /* ----- 变量 START ----- */
    const loading = ref(false);
    const loadEnd = ref(false);
	const title = import.meta.env.VITE_TITLE || '应运';// 标题
	const activeTopMenu = ref('/');// 默认顶部导航选中项
	const activeLeftMenu = ref('/');// 默认左侧导航选中项
	const isCollapse = ref(true);// 侧边栏是否展开
	const leftMenuWidth = ref('150px');// 定义侧边栏宽度
	const leftShow = false;//!useRoute().meta.hide_left ? true : false;
	const menuTopList = ref([]);//顶部导航栏
	const menuLeftList = ref([]);//左侧导航栏
	const menuLeftCurrent = ref([]);//左侧导航栏当前选中值
	const userInfo = ref({});//管理员信息

  	const _useRouter = useRouter(); // 获取当前路由对象

  	var store = reactive(useUserInfoStore());
 	/* ----- 变量 END ----- */

    /* ----- 函数 START ----- */
    onBeforeMount(() => {
    	GetSysConfig();// 获取配置信息
    	GetAssets();// 获取账号余额信息

    	document.title = title;

        // 组件挂载之前
        // Init();
        if(useRoute().matched.length > 1) {
			activeTopMenu.value = useRoute().matched[1].path;
			activeLeftMenu.value = useRoute().fullPath;
		}
		
		webSocketInif();
    });

    onMounted(() => {
        // 组件挂载完成
        
    });

    onBeforeUnmount(() => {
      	// 组件卸载之前
      	eventFn('unmount');

    });

    onUnmounted(() => {
      	// 组件卸载完成
    });

    // 创建一个计算属性来观测路由的变化
    watch(() => _useRouter.currentRoute.value, (to) => {
      	if(to.matched.length > 1) {
			activeTopMenu.value = to.matched[1].path;
			activeLeftMenu.value = to.fullPath;
		}

		if(to.meta && to.meta.title) {
			document.title = to.meta.title + ' - ' + title;
		} else {
			document.title = title;
		}

		GetAssets();
    });

    const waitAmountCount = ref(0);
    const wsToken = ref('');
    const webSocketInif = () => {
    	webSocket.init();

    	webSocket.ws.onopen = () => {
    		webSocket.sendMessage(`{"act": "login","token": "${store.token}"}`);
    	};

    	webSocket.ws.onmessage = (event) => {
    		try{
	    		const data = JSON.parse(event.data);
	       		
	       		if(data.act == 'notify') {
	       			var message = JSON.parse(data.msg);

	       			if(typeof message.waitAmountCount == 'number') {
		       			waitAmountCount.value = message.waitAmountCount;
		       			if(message.waitAmountCount > 0) {
		       				GetAssets();
		       				showNotification('广告转入待审通知', '您当前有'+message.waitAmountCount+'条待审记录');
		       			}
		       		} else {
		       			showNotification('通知', data.msg);
		       		}
	       		}
	       	} catch {

	       	}
    	};

    	webSocket.ws.onerror = () => {
    		console.log('WebSocket error');
    	};

    	webSocket.ws.onclose = () => {
    		console.log('WebSocket closed');
    	};
    }

    // 消息通知
    const showNotification = (title, msg) => {
		if(window.Notification) {
			if(window.Notification.permission =="granted") {
				var notification = new Notification(title, {
					body: msg,
					icon: 'https://yy.vocompany.ltd/logo.png'
				});
				setTimeout(function(){
					notification.close();
				},5000);
			} else {
				window.Notification.requestPermission();
			}
		}
	}

    // 遍历路由，生成顶部导航栏、左侧导航栏
    const RouteToMenu = () => {
    	var menus = _useRouter.options.routes[1].children;
    	
    	var menuChildren = [];

    	//顶部导航变量
    	var topMenu = [];
    	var topChildren = [];
    	let topCurrent = 0;

    	//左侧导航变量
    	var leftMenu = [];
    	var leftChildren = [];
    	let leftCurrent = 0;

    	for(var value in menus) {
    		if(!menus[value].meta || (!menus[value].meta.topMenu && !menus[value].meta.leftMenu)) {
    			continue;
    		}

    		if(checkRouteAuth(menus[value].meta.auth)) {
    			continue;
    		}

    		topChildren = [];
    		leftChildren = [];
    		if(menus[value].children) {
    			menuChildren = menus[value].children;
    			for (var val in menuChildren) {
    				if(menuChildren[val].meta) {
    					if(checkRouteAuth(menuChildren[val].meta.auth)) {
			    			continue;
			    		}

    					//需要显示顶部导航时，加入topChildren列表
	    				if(menus[value].meta.topMenu && menuChildren[val].meta.topMenu) {
		    				topChildren.push({
		    					name: menuChildren[val].meta.title,
			    				url: menuChildren[val].path ? menus[value].path + '/' + menuChildren[val].path : menus[value].path,
			    				act: menus[value].path
		    				})
		    			}

		    			//需要显示左侧导航时，加入leftChildren列表
	    				if(menus[value].meta.leftMenu && menuChildren[val].meta.leftMenu) {
		    				leftChildren.push({	
		    					name: menuChildren[val].meta.title,
			    				url: menuChildren[val].path ? menus[value].path + '/' + menuChildren[val].path : menus[value].path,
			    				act: menus[value].path
		    				})
		    			}
		    		}
    			}
    		}

    		if(menus[value].meta.topMenu) {
	    		if(topChildren.length == 1) {
	    			topMenu[topCurrent++] = {
	    				name: topChildren[0].name,
	    				url: topChildren[0].url,
	    				act: topChildren[0].act,
	    				children: [],
	    			};
	    		} else {
	    			
	    			topMenu[topCurrent++] = {
	    				name: menus[value].meta.title,
	    				url: menus[value].path,
	    				act: menus[value].path,
	    				children: topChildren,
	    			};
	    		}
	    	}

    		if(menus[value].meta.leftMenu) {
    			menuLeftCurrent.value.push(leftCurrent);
    			leftMenu[leftCurrent++] = {
					name: menus[value].meta.title,
					url: menus[value].path,
					act: menus[value].path,
					icon: menus[value].meta.icon,
					children: leftChildren,
				};
			}
    	}

    	menuTopList.value = topMenu;
    	menuLeftList.value = leftMenu;
    }

    // 检查路径权限
    const checkRouteAuth = (metaAuth) => {
    	return metaAuth && metaAuth.length > 0 && auth.value.indexOf(metaAuth) == -1;
    }

	// 接收子组件事件
	const eventFn = (event, data, callback) => {
		if(event == 'unmount') {
			// 卸载组件时销毁运行中的部分组件
			if(loading.value) {
				loading.value.close();
				loading.value = null;
			}
		} else if(event == 'loading') {
			// 加载状态
			if(data) {
				loading.value = ElLoading.service({ fullscreen: true })
			} else if(loading.value) {
				loading.value.close();
			}
		} else if(event == 'updateAmount') {
			// 更新资产信息
			GetAssets();
		}
	}

	// 系统配置信息
	const auth = ref([]);
	const GetSysConfig = () => {
		userInfo.value = store.getUserInfo;

		if(!userInfo.value) {
			return routers.replace('/');
		}

		getSysConfig().then(ret => {
			if(ret.code == 200) {
				auth.value = ret.data.auth.power;

				RouteToMenu();
			} else {
				ShowMsg('error', ret.msg);
			}
		}).catch(err => {
			ShowMsg('error', '获取配置失败');
		});
	}

	// 安全退出
	const ToLogout = () => {
        loading.value = true;
        toLogout({}).then(ret => {
            loading.value = false;
            if(ret.code == 200) {
            	ShowMsg('success', ret.msg);
                store.clear();
                routers.replace('/');
            } else {
                ShowMsg('error', ret.msg);
            }
        }).catch(err => {
            loading.value = false;
            ShowMsg('error', err.message ? err.message : err);
            console.error('ToLogout Err');
        });
    }

    // 资产信息
    const amount = ref("0");
    const canAmount = ref('0');
    const credit = ref("0");
    const tiktokAmount = ref("0");
    const waitAmount = ref('');
    const GetAssets = ()=>{
    	amount.value = '-';
        credit.value = '-';
        tiktokAmount.value = '-';

        var data = {};
    	getAssets().then(ret => {
            amount.value = ret.data.amount.toFixed(2);
            credit.value = ret.data.credit.toFixed(2);
            waitAmountCount.value = ret.data.waitAuditCount;

            canAmount.value = (ret.data.amount - ret.data.waitAmount).toFixed(2);

            waitAmount.value = ret.data.waitAmount > 0 ? ret.data.waitAmount.toFixed(2) : '';
            tiktokAmount.value = ret.data.tiktokAmount;
        }).catch(ret=>{
            //失败
            ShowMsg('error', '获取账号余额信息失败');
        });
    }
	/* ----- 函数 END ----- */

    // 清理缓存
    const ClearCache = () => {
    	clearCache().then(ret => {
    		if(ret.code == 200) {
    			store.setUserInfo(ret.data.info);
    			GetSysConfig();
    			ShowMsg('success', '清理缓存成功');
    		} else {
    			ShowMsg('error', ret.msg);
    		}
    	}).catch(err => {
    		ShowMsg('error', '清理缓存失败');
    	});
    }
</script>

<template>
	<section class="base-layout">
		<section class="base-layout-header">
			<section class="base-layout-header-content">
				<span class="left">
					<el-image class="logo" fit="fill" :src="logo"/>
				</span>
				<span class="center">
					<div class="menu-top">
						<el-menu
						    mode="horizontal"
						    background-color="#353535"
						    :default-active="activeTopMenu"
						    :popper-class="'topmenu'"
						    :router="true"
						    text-color="#fff"
						    active-text-color="#fff"
						>
							<template v-for="item in menuTopList">
								<template v-if="item.children && item.children.length > 0">
									<el-sub-menu :index="item.url">
										<template #title>{{ item.name }}</template>
										<template v-for="val in item.children">
											<el-menu-item :index="val.url">{{ val.name }}</el-menu-item>
										</template>
								    </el-sub-menu>
								</template>
								<template v-else>
									<el-menu-item :index="item.url">{{ item.name }}</el-menu-item>
								</template>
							</template>
					  </el-menu>
					</div>
				</span>
				<span class="right">
					<span class="valign business-asstes">
						<span v-if="userInfo.role_id == 1" class="m-right-12">TikTok总余额 : {{tiktokAmount}}</span>
						<el-tooltip
							v-else
					        effect="light"
					        :content="'总余额 : '+amount+'，可用余额：'+canAmount+(waitAmount ? '，待入账：'+waitAmount : '')"
					        placement="bottom"
					     >
							<span class="m-right-12">总余额 : {{amount}}</span>
						</el-tooltip>
						<span v-if="waitAmount" class="m-right-12">待入账 : {{waitAmount}}</span>
					</span>

					<span class="valign">
						<el-dropdown class="dropdown">
							<span>
								<el-icon size="18" class="valign user-icon"><UserFilled /></el-icon>
								{{userInfo.nickname}}
							</span>
							<template #dropdown>
								<el-dropdown-menu class="dropdown-menu">
									<!-- <el-dropdown-item>修改密码</el-dropdown-item> -->
									<el-dropdown-item @click="ClearCache">清理缓存</el-dropdown-item>
									<el-dropdown-item @click="ToLogout">安全退出</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</span>
				</span>
			</section>
		</section>

		<section class="base-layout-center">
			<section class="left">
				<el-card class="card-box">
					<template v-for="(item,key) in menuLeftList">
						<el-collapse v-model="menuLeftCurrent">
					      <el-collapse-item :name="key">
					      	<template #title>
					      		<span class="menu-left-title">
						      		<component :is="item.icon" class="menu-left-title-icon"></component>
						      		<span class="menu-left-title-text">{{ item.name }}</span>
					      		</span>
					      	</template>
					      	<el-row class="children-menu" :gutter="20">
						        <template v-for="val in item.children">
									<el-col :span="12"> 
										<template v-if="val.url == '/system/partner'">
											<el-badge :value="waitAmountCount" :show-zero="false" :offset="[10,10]">
												<el-link @click="Jump(val.url)" :class="activeLeftMenu == val.url ? 'lm-active lm-default' : 'lm-default'" :underline="false"> {{ val.name }} </el-link>
											</el-badge>
										</template>
										<template v-else>
											<el-link @click="Jump(val.url)" :class="activeLeftMenu == val.url ? 'lm-active lm-default' : 'lm-default'" :underline="false"> {{ val.name }} </el-link>
										</template>
									</el-col>
						        </template>
					        </el-row>
					      </el-collapse-item>
					    </el-collapse>
				    </template>					
				</el-card>
			</section>
			<section class="right">
				<el-card class="card-box">
					<template #header>
			            <div class="card-header">
			                <breadcrumb />
			            </div>
			        </template>
			        <div class="card-content">
						<RouterView v-if="auth.length>0" @event-fn="eventFn" v-model:auth="auth"/>
					</div>
				</el-card>
				<el-backtop style="color: #353535;" target=".base-layout-center .right" :bottom="100" :right="50">UP</el-backtop>
			</section>
		</section>
	</section>
</template>
<style type="text/css">
	.el-menu--horizontal {
	  --el-menu-horizontal-height: 80px;
	  
	}
	.el-sub-menu__title,.el-menu-item,.business-asstes,.dropdown .el-tooltip__trigger{
		font-size: 18px!important;
	}
	.el-menu--horizontal .el-menu .el-menu-item{
		height: 52px!important;
		line-height: 52px!important;
	}
	/*未激活状态字体颜色及背景色*/
	.el-menu--horizontal .el-menu .el-menu-item.is-active{
		color:var(--el-menu-text-color)!important;
	}
	/*激活状态字体颜色及背景色*/
	.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
	    background-color: #6d6d6d!important;
	    color:white!important;
	}
	.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
		/* background-color: white;*/
	}
	/*去除导航下划线*/
	.el-menu--horizontal.el-menu{
		border-bottom: none!important;
	}

	/* 左侧导航栏 去除外边框*/
	.base-layout-center .left .el-collapse{
		border:none!important;
	}
	.base-layout-center .left .el-collapse-item__header{
		border:none!important;
	}
	.base-layout-center .left .el-collapse-item__wrap{
		border-bottom: none!important;
	}
	.valign .el-dropdown .el-tooltip__trigger{
		padding:0 8px 18px 8px;
	}

	.valign .el-dropdown .el-tooltip__trigger:hover,.valign .el-dropdown .el-tooltip__trigger:focus {
	  border-bottom: 2px solid white;
	  outline:none;
	  color: white;
	  background-color: var(--el-color-black);
	}

	.el-dropdown-menu {
    	background-color: var(--el-color-black)!important;
    	color: var(--el-color-white)!important;
	}
	.el-dropdown-menu__item:not(.is-disabled){
		background-color: var(--el-color-black)!important;
    	color: var(--el-color-white)!important;
	}
	.el-dropdown-menu__item:not(.is-disabled):focus {
	    background-color: #6d6d6d!important;
	    color: var(--el-color-white)!important;
	}
	.lm-default .el-link__inner{
		color: var(--el-color-black-light-9)!important;
	}
	.lm-active .el-link__inner{
		color: var(--el-color-black-light-3)!important;
		font-weight: bold!important;
	}
</style>
<style scoped lang="scss">
	$_header: 80px;
	$_footer: 0px;

	.base-layout{
		min-width:1000px;
		color: white;
		font-size: 14px;
		overflow-y: auto;
		.base-layout-header{
			height: $_header;
			background: var(--el-color-black);
			padding:0 20px;
			box-sizing: border-box;
			
			.base-layout-header-content{
				display: flex;
				line-height: $_header;
				white-space: nowrap;

				.left{
					padding-right:50px;
					.logo{
						display: inline-block;
						height: 60px;
						width: 60px;
						vertical-align: middle;
					}
				}

				.center{
					flex:1;
					.menu-top{
						.menu-top-link{
							padding:0 10px;
							text-decoration: none;
							color:white;
						}
						.menu-top-link-active, .menu-top-link:hover{
							color:white;
						}
					}
				}

				.right{
					width:auto;
					padding-right: 6px;
					.business-asstes{
						.m-right-12{
							margin-right: 12px;
						}
					}
					.valign{
						vertical-align: middle;
						border: 0;
						.dropdown{
							border: 0;
							cursor: pointer;
							color: white;
							display: contents;
							align-items: center;
						}
					}
					.user-icon{
						margin-right: 8px;
					    color: var(--el-color-black);
					    background: var(--el-color-white);
					    border-radius: 50%;
					    padding: 6px;
					}
				}
			}
		
		}

		.base-layout-center{
			position: relative;
			height: calc(100vh - $_header - $_footer);
/*			display: flex;*/

			.left {
				position: absolute;
				top: 10px;
				left: 10px;
				bottom: 10px;
/*				padding:10px 10px 0;*/
/*				min-width: 240px;*/
				width: 280px;
/*				height: calc(100vh - $_header - $_footer - 30px);*/

				.menu-left-title {
					.menu-left-title-icon{
						width:14px;
						vertical-align: middle;
						margin-right:8px;
					}

					.menu-left-title-text {
						vertical-align: middle;
						display: inline-block;
						font-size: 14px;
					    font-style: normal;
					    font-weight: bold;
					    line-height: 20px;

					}
				}
				.children-menu{
					padding-top:10px;
				}

				.card-box{
					height: 100%;
				}
			}
			.right {
				position: absolute;
				top: 10px;
				left: 300px;
				right: 10px;
				bottom: 10px;

				.card-box{
					height: 100%;
					.card-content{
						overflow: auto;
						padding-right:10px;
						box-sizing: border-box;
					}
				}
			}
		}
	}
</style>