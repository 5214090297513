import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import LayoutView from '@/layouts/baseLayout.vue'


const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      meta: {
        title: '登陆',
        hide_left: true,
      },
      component: () => import('@/views/login.vue')
    },
    {
      path: '/',
      name: 'home',
      component: LayoutView,
      children: [
        {
          path: '/home',
          name: 'home',
          meta: {
            title: '首页',
            topMenu: true,
            leftMenu: false,
            icon:'HomeFilled',
          },
          component: () => import('@/views/home/index.vue'),
        },
        {
          path: '/account',
          name: 'account',
          meta: {
            title: '账户管理',
            topMenu: true,
            leftMenu: true,
            icon:'UserFilled',
            auth:'Account'
          },
          children: [
            {
              path: '',
              name: 'account-list',
              meta: {
                title: '账户列表',
                topMenu: true,
                leftMenu: true,
                auth:'Account-List'
              },
              component: () => import('@/views/account/list.vue'),
            },
            {
              path: 'apply',
              name: 'account-apply',
              meta: {
                title: '账户申请',
                topMenu: true,
                leftMenu: true,
                auth:'Account-Apply'
              },
              component: () => import('@/views/account/apply.vue'),
            },
            {
              path: 'transfer',
              name: 'account-transfer',
              meta: {
                title: '账户提审',
                topMenu: true,
                leftMenu: true,
                auth:'Account-Transfer'
              },
              component: () => import('@/views/account/transfer.vue'),
            },
          ]
        },
        // {
        //   path: '/pay',
        //   name: 'pay',
        //   meta: {
        //     title: '充值管理',
        //     topMenu: true,
        //     leftMenu: true,
        //     icon:'Money',
        //     auth:'Pay'
        //   },
        //   children: [
            

        //     // {
        //     //   path: 'auth',
        //     //   name: 'pay-auth',
        //     //   meta: {
        //     //     title: '授信中心',
        //     //     topMenu: false,
        //     //     leftMenu: false,
        //     //     auth:'Pay-Auth'
        //     //   },
        //     //   component: () => import('@/views/pay/auth.vue'),
        //     // },
        //   ]
        // },
        {
          path: '/bill',
          name: 'bill',
          meta: {
            title: '财务管理',
            topMenu: true,
            leftMenu: true,
            icon:'Tickets',
            auth:'Bill'
          },
          children: [
            {
              path: 'pay',
              name: 'pay-index',
              meta: {
                title: '充值中心',
                topMenu: true,
                leftMenu: true,
                auth:'Pay-Index'
              },
              component: () => import('@/views/pay/index.vue'),
            },  

            {
              path: 'payment',
              name: 'payment-list',
              meta: {
                title: '收款设置',
                topMenu: false,
                leftMenu: false,
                auth:'Pay-List'
              },
              component: () => import('@/views/pay/paymentset.vue'),
            },


            {
              path: 'apply',
              name: 'pay-list',
              meta: {
                title: '充值列表',
                topMenu: true,
                leftMenu: true,
                auth:'Pay-List'
              },
              component: () => import('@/views/pay/list.vue'),
            },
            {
              path: 'list',
              name: 'bill-list',
              meta: {
                title: '我的账单',
                topMenu: true,
                leftMenu: true,
                auth:'Bill-List'
              },
              component: () => import('@/views/bill/list.vue'),
            },
          ]
        },
        {
          path: '/record',
          name: 'record',
          meta: {
            title: '日志管理',
            topMenu: false,
            leftMenu: true,
            icon:'Coin',
            auth:'Record'
          },
          children: [
            {
              path: 'task',
              name: 'record-task',
              meta: {
                title: '任务日志',
                topMenu: true,
                leftMenu: true,
                auth:'Record-Task'
              },
              component: () => import('@/views/record/task.vue'),
            },
            {
              path: 'export',
              name: 'record-export',
              meta: {
                title: '导出日志',
                topMenu: true,
                leftMenu: true,
                auth:'Record-Export'
              },
              component: () => import('@/views/record/export.vue'),
            }
          ]
        },
        {
          path: '/business',
          name: 'business',
          meta: {
            title: '子账号管理',
            topMenu: false,
            leftMenu: true,
            icon:'User',
            auth:'Business'
          },
          children: [
            {
              path: 'son',
              name: 'business-son',
              meta: {
                title: '子账号',
                topMenu: true,
                leftMenu: true,
                auth:'Business-Son'
              },
              component: () => import('@/views/business/son.vue'),
            },
          ]
        },
        {
          path: '/consume',
          name: 'consume',
          meta: {
            title: '数据消耗',
            topMenu: false,
            leftMenu: true,
            icon:'Coin',
            auth:'Consume'
          },
          children: [
            {
              path: 'custom',
              name: 'consume-custom',
              meta: {
                title: '客户消耗',
                topMenu: true,
                leftMenu: true,
                auth:'Consume-Custom'
              },
              component: () => import('@/views/consume/custom.vue'),
            },
          ]
        },
        {
          path: '/system',
          name: 'system',
          meta: {
            title: '系统管理',
            topMenu: true,
            leftMenu: true,
            icon:'Tools',
            auth:'System'
          },
          children: [
            {
              path: 'admin',
              name: 'system-admin',
              meta: {
                title: '管理员',
                topMenu: true,
                leftMenu: true,
                auth:'System-Admin'
              },
              component: () => import('@/views/system/admin.vue'),
            },
            {
              path: 'business',
              name: 'system-business',
              meta: {
                title: '平台用户',
                topMenu: true,
                leftMenu: true,
                auth:'System-Business'
              },
              component: () => import('@/views/system/business.vue'),
            },
            {
              path: 'partner',
              name: 'system-partner',
              meta: {
                title: '转入审核',
                topMenu: true,
                leftMenu: true,
                auth:'System-Business'
              },
              component: () => import('@/views/system/transferaudit.vue'),
            },
            {
              path: 'advertiser',
              name: 'system-advertiser',
              meta: {
                title: '广告账号',
                topMenu: true,
                leftMenu: true,
                auth:'System-Business'
              },
              component: () => import('@/views/system/advertiser.vue'),
            },
            {
              path: 'transrecord',
              name: 'bill-transrecord',
              meta: {
                title: '交易记录',
                topMenu: true,
                leftMenu: true,
                auth:'Bill-Transrecord'
              },
              component: () => import('@/views/bill/transrecord.vue'),
            },
            {
              path: 'dashboard',
              name: 'System-Dashboard',
              meta: {
                title: '仪表盘',
                topMenu: true,
                leftMenu: true,
                auth:'System-Dashboard'
              },
              component: () => import('@/views/system/dashboard.vue'),
            },
          ]
        },
        

        {
          path: '/sysbill',
          name: 'sysbill',
          meta: {
            title: '账单统计',
            topMenu: true,
            leftMenu: true,
            icon:'Tickets',
            auth:'Sysbill'
          },
          children: [
            {
              path: '',
              name: 'sysbill',
              meta: {
                title: '账单列表',
                topMenu: true,
                leftMenu: true,
                auth:'Sysbill'
              },
              component: () => import('@/views/sysbill/list.vue'),
            },
           
          
        
        
          ]
        },


      ]
    },
  ]
})

export default router
